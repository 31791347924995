import React, { useState, useEffect, useRef } from 'react';

function Header({ activeAnchor }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null); // Ссылка на меню

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const moveToSection = (anchor) => {
    if (window.fullpage_api) {
      window.fullpage_api.moveTo(anchor);
    } else {
      // Прокрутка вручную при отсутствии fullpage.js
      const section = document.querySelector(`[data-anchor="${anchor}"]`);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setMenuOpen(false); // Закрываем меню после выбора секции
  };

  // Обработчик кликов вне меню
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false); // Закрываем меню
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  const menuItems = [
    { anchor: 'block2', label: 'О заполярье' },
    { anchor: 'block3', label: 'Урожай' },
    { anchor: 'block4', label: 'Характеристики' },
    { anchor: 'block5', label: 'Товары' },
    { anchor: 'block6', label: 'Статьи' },
    { anchor: 'footer', label: 'Контакты' },
  ];

  return (
    <header className="fixed lg:absolute top-0 w-full flex items-center justify-between px-6 lg:px-8 py-3 bg-black text-white shadow-md z-50 text-lg lg:text-lg">
      <img src="/images/logo.png" alt="Логотип" className="w-20 lg:w-28" />

      {/* Основное меню для десктопа и мобильного устройства */}
      <nav
        className={`${
          menuOpen ? 'flex' : 'hidden'
        } lg:flex flex-col lg:flex-row lg:space-x-6 absolute lg:static top-full left-0 w-full lg:w-auto bg-black lg:bg-transparent text-center lg:text-left`}>
        <ul className="lg:flex lg:space-x-6 py-4 lg:py-0 w-full lg:w-auto">
          {menuItems.map((item) => (
            <li key={item.anchor}>
              <button
                onClick={() => moveToSection(item.anchor)}
                className={`transition-colors hover:text-[#D95F03] ${
                  activeAnchor === item.anchor ? 'text-[#D95F03]' : ''
                } block w-full lg:w-auto py-2 md:py-0`}>
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* Кнопка для мобильного меню */}
      <div className="flex gap-3">
        <button
          className="block lg:hidden focus:outline-none"
          onClick={() => setMenuOpen(!menuOpen)}>
          <div className="hamburger-icon w-8 h-6 flex flex-col justify-between items-center">
            <span className="block w-full h-0.5 bg-white"></span>
            <span className="block w-full h-0.5 bg-white"></span>
            <span className="block w-full h-0.5 bg-white"></span>
          </div>
        </button>

        {/* Блок с номером телефона и ссылками */}
        <div className="relative">
          <div
            className="lg:flex hidden group cursor-pointer text-right bg-[#D95F03] rounded-full px-4 py-3 text-white"
            onClick={toggleMenu}>
            <div className="lg:block hidden">+7 (921) 758-4440</div>
          </div>
          <div className="block lg:hidden group cursor-pointer" onClick={toggleMenu}>
            <img src="/images/button_phone.svg" className="w-10 h-10" alt="Телефон" />
          </div>
          {showMenu && (
            <div
              ref={menuRef} // Привязываем меню к ссылке
              className="absolute top-full -left-40 lg:left-0 mt-2 w-52 lg:w-full bg-white shadow-lg rounded-lg overflow-hidden">
              <a href="tel:+79217584440" className="block px-4 py-2 text-black hover:bg-gray-200">
                Позвонить
              </a>
              <a
                href="https://wa.me/79217584440"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 text-black hover:bg-gray-200">
                Написать в WhatsApp
              </a>
              <a
                href="https://t.me/EvgNovikov"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 text-black hover:bg-gray-200">
                Написать в Telegram
              </a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
