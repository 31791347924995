import React, { useState } from 'react';
import Modal from 'react-modal';
import { modalTexts } from './modalContent'; // Импортируем тексты

Modal.setAppElement('#root');

function Block6({ setIsBlock6ModalOpen }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0); // Индекс текста

  const openModal = (index) => {
    setModalIndex(index);
    setIsModalOpen(true);
    setIsBlock6ModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsBlock6ModalOpen(false);
  };

  return (
    <div
      className="relative h-full w-full py-8 lg:py-24 bg-cover text-white flex flex-col justify-center items-center"
      style={{ backgroundImage: 'url("/images/block4.jpg")' }}>
      <div className="w-full bg-black px-4 py-6 lg:px-16 shadow-lg">
        {/* Две кнопки с разными текстами */}
        <button
          className="bg-white w-full text-start text-black text-xl lg:text-2xl px-4 py-3 lg:px-8 lg:py-4 rounded-lg cursor-pointer mb-4"
          onClick={() => openModal(0)}>
          {modalTexts[0].title}
        </button>
        <button
          className="bg-white w-full text-start text-black text-xl lg:text-2xl px-4 py-3 lg:px-8 lg:py-4 rounded-lg cursor-pointer"
          onClick={() => openModal(1)}>
          {modalTexts[1].title}
        </button>

        {/* Модальное окно */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Описание"
          className="bg-white text-black max-w-full mx-auto p-8 rounded-lg shadow-lg overflow-y-auto mt-64 py-8 z-0"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto">
          <button className="mb-6 bg-black text-white px-6 py-3 rounded-lg" onClick={closeModal}>
            Закрыть
          </button>
          <h2 className="text-2xl lg:text-3xl font-bold mb-4">{modalTexts[modalIndex].title}</h2>
          <div
            className="text-sm lg:text-base leading-relaxed h-[100vh]"
            dangerouslySetInnerHTML={{ __html: modalTexts[modalIndex].content }}></div>
        </Modal>
      </div>
    </div>
  );
}

export default Block6;
